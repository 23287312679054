@charset "UTF-8";

.grayBox {
  //background:#f6f6f6;
  padding:rem(30);
  .global__subTit {
    font-size:rem(25);
    @include font();
    margin-bottom:rem(10);
  }
  li {
    line-height:1.5;
    font-size:rem(16);
    color:#313131;
    @include font("dLight");
  }
  ul {
    margin-left:rem(10);
  }
  &__tit {
    font-size:rem(16);
    @include font("medium");
    margin-top:rem(25);
  }
  &__txt {
    font-size:rem(16);
    @include font("dLight");
    margin-top:rem(10);
    line-height:1.5;
  }
  &__ol {
    margin-top:rem(25);
  }
  &+.layout-footer   {
    margin-top:0;
  }
}