@charset "UTF-8";

$borderColor:#d6d6d6;
.hist {
  @include clearFix();
  margin-top:rem(40);
  position:relative;
  &:before {
    content:"";
    display:block;
    position:absolute;
    left:130px;
    width:1px;
    background:$borderColor;
    top:40px;
    bottom:0;
  }
  &__tit {
    font-size:rem(25);
    margin-bottom:10px;
    margin-top:30px;
    padding:0 30px;
    @include font("dLight");
    &.-blue {
      color:#3d7eeb;
      padding:0;
      margin:0;
      display:block;
    }
    &+.hist__subTxt {margin-top:10px;}
  }
  &__subTxt {
    font-size:rem(16);
    line-height:1.5;
    padding:0 rem(30);
  }
  &__item {
    font-size:0;
    display:flex;
    padding:0 rem(30);
    &:not(:first-of-type) {
      margin-top:20px;
    }
    &:first-of-type {
      .hist {
        &__year {
          background:#3173e6;
          color:#fff;
          &:before,&:after {
            background:#3173e6;
          }
        }
      }
    }
  }
  &__year {
    font-size:rem(20);
    display:inline-block;
    min-width:rem(85);
    height:rem(85);
    line-height:rem(85);
    text-align: center;
    vertical-align: top;
    background:#f6f6f6;
    border-radius:50%;
    @include font("medium");
    position:relative;
    margin-right:20px;
    &:before {
      content:"";
      width:20px;
      height:1px;
      background:$borderColor;
      display:block;
      position:absolute;
      right:-20px;
      top:41px;
    }
    &:after {
      content:"";
      display:block;
      position:absolute;
      top:50%;
      transform: translateY(-50%);
      right:-20px;
      width:10px;
      height:10px;
      background:#d6d6d6;
      border-radius:50%;
    }
  }
  &__infoBox {
    display:inline-block;
    vertical-align: top;
    margin:30px 0 0 20px;
  }
  &__info {
    position:relative;
    padding-top:3px;
  }
  &__mons {
    font-size:rem(15);
    @include font("medium");
    display:block;
    text-align:left;
  }
  &__txt {
    overflow:hidden;
    font-size:rem(14);
    line-height:20px;
    @include font("dLight");
    &:not(:first-of-type) {
      margin-top:10px;
    }
  }
}

html:lang(en) {
  .hist__tit {
    font-size:rem(24);
    line-height:1.4;
    @include font("light");
    &+.hist__subTxt {
      @include font("light");
    }
  }
  .hist__mons {
    font-size:rem(17);
  }
  .hist__txt {font-size:rem(13)}
}