@charset "UTF-8";
//게시판 리스트형
.bbsList {
  &__item {
    width:100%;
    height:rem(104);
    position:relative;
    padding:0 rem(30);
    display:flex;
    align-items: center;
      border-bottom:1px solid #dce3e8;
  }
  &__num,&__txtArea {}
  &__num {
    display:none;
  }
  &__txtArea {
    width:100%;
    h2 {
      font-size:rem(17);
      width:100%;
      @include font("dLight");
      @include ellipsis_multi();
      margin-bottom:rem(6);
    }
  }
  &__name,
  &__data,
  &__count {
    display:inline-block;
    font-size:rem(12);
    @include font("dLight");
    color:#717171;
  }
  &__name {

  }
  &__data,&__count {
    margin-left:rem(10);
    position:relative;
    &:before {
      content:"|";
      display:block;
      position:absolute;
      left:rem(-8);
      top:0;
      font-size:rem(11);
    }
  }
  &__empty {
    text-align:center;
    padding:200px 0;
    border-top:1px solid #d4d4d4;
    border-bottom:1px solid #d4d4d4;
  }
  &__type {
    display:inline-block;
    vertical-align: middle;
    line-height:rem(20);
    min-width:rem(60);
    padding:0 rem(5);
    color:#fff;
    font-size:rem(12);
    @include font("light");
    margin-right:rem(10);
    text-align:center;
    background:#2497a4;
  }
}

//게시판 뷰
.bbsView {
  position:relative;
  header {
    padding:rem(22) rem(30) rem(15);
  }
  .bbsList__type {margin:0 rem(5) rem(5) 0;vertical-align: baseline}
  &__tit {
    font-size:rem(23);
    @include font();
    margin-bottom:rem(5);
  }
  &__type {
    display:inline-block;font-size:rem(12);
    @include font("bold");
  }
  &__txt {display:block;line-height:1.5}
  &__data {
    font-size:rem(12);display:inline-block;
    &:not(:first-of-type) {
      &:before {
        content:"|";
        display:inline-block;
        margin:0 5px;
      }
    }
  }
  &__content {
    border-top:1px solid #eceaeb;
    border-bottom:1px solid #eceaeb;
    padding:rem(25) rem(30);
    min-height:rem(200);
    img {max-width:100%;height:auto !important;margin:10px 0;}
    font-size:rem(16);
    @include font("dLight");
    line-height:1.5;
  }
  &__file {
    position:relative;
    font-size:0;
    @include font("dLight");
    @at-root header+.bbsView__file {
      border-bottom:none;
      .bbsView__fileList:last-of-type {border-bottom:0}
    }
  }
  &__fileName {
    position:absolute;
    left:0;
    font-size:rem(16);
    display:none;
  }
  &__fileList {
    font-size:rem(16);
    display:block;
    padding:rem(15) rem(30);
    border-top:1px solid #eceaeb;
    &:before {
      content:"";
      display:inline-block;
      @extend .iconFile;
      vertical-align: middle;
      margin-right:rem(5);
    }
  }
  &__fileList:last-of-type {
    &:after {display:none}
  }
  &__fileList+&__fileList {}
  &__nav {
    display:none;
    a {
      display:inline-block;
      border:1px solid #313131;
      width:50px;
      line-height:48px;
      text-align:center;
      border-radius:4px;
      vertical-align: middle;
      &+a {margin-left:8px}
    }
    span {
      @include txtHidden();
      vertical-align: middle;
    }
  }
  &+.btnGroup {
    margin-top:rem(30);
  }
}

.bbsVideo {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}


//포토갤러리
.photoList {
  overflow:hidden;
  &__item {
    width:100%;
    display:block;
    margin-bottom:rem(30);

  }
  &__thum {
    overflow:hidden;
    background-position:center center;
    background-repeat: no-repeat;
    background-size:cover;
    height:0;
    padding-top:(504/750*100%);
    width:100%;
    position:relative;
  }
  &__type {
    font-size:rem(12);
    line-height:rem(20);
    padding:0 rem(5);
    display:inline-block;
    margin-left:rem(30);
  }
  &__txt {
    margin-top:rem(20);
    font-size:rem(17);
    @include font("dLight");
    @include ellipsis_multi(2);
    width:100%;
    padding:0 rem(30);
    &+.photoList__data {margin-left:rem(30)}
  }
  &__data {
    font-size:rem(12);
    @include font("dLight");
    color:#717171;
    margin-top:rem(5);
    display:inline-block;
    margin-left:rem(10);
  }
  &.-video {
    .photoList {
      &__thum {
        position:relative;
        &:before {
          content:"";
          width:100%;
          height:100%;
          left:0;top:0;
          position:absolute;
          z-index:0;
          background:#000;
          opacity: .3;
          display:block;
        }
        &:after {
          content:"";
          display:block;
          @include iconPlus_big;
          opacity: .8;
          position:absolute;
          left:50%;
          top:50%;
          margin:-29px 0 0 -29px
        }
      }
    }
  }
}
//게시판 리스트 상단 텍스트

.subContentsTitle{
  border-bottom:2px solid #3173e6;
  padding: rem(30) 0;

  &__txt{
    padding: 0 rem(30);
    font-size: rem(16);
    line-height: rem(26);
  }
}

.video__content {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin:15px 0;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

