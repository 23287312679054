@charset "UTF-8";

.layerPopup {
  display:none;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.4);
  text-align:center;
  @include before-middle();
  z-index: 2010;
  transition: all .3s;
  &.-open {
    .layerPopup__layer {
      top:0 !important;
    }
  }
  &__layer {
    display:inline-block;
    vertical-align: middle;
    background:#fff;
    border-radius:15px 0 15px;
    overflow:hidden;
    padding:rem(20);
    position:relative;
    transition: all 0.5s;
    width:310px;
  }
  &__pwd {
    display:block;
    width:100%;
    line-height:40px;
    height:40px;
    border:1px solid #b8bdc0;
    margin:rem(20) auto rem(35);
    padding:0 10px;
  }
  &__tit {
    font-size:rem(25);
    @include font();
    margin-bottom:rem(15);
  }
  &__txt {
    font-size:rem(16);
    line-height:1.6;
    @include font("light");
  }
  &.-info {
    width:100%;
    min-width:0;
    border-radius:0;
    .layerPopup {
      &__layer {
        text-align:left;
        position:fixed;
        left:0;
        top:100%;
        width:100%;
        height:100vh;
        border-radius:0;
        overflow-y:auto;
        padding:0;
        -webkit-overflow-scrolling:touch;
      }
    }
  }
  &__close {
    display:block;
    position:absolute;
    right:20px;
    top:20px;
  }
  .btnGroup {
    padding:0;
  }
  &__txtWrap {
    padding:0 30px;
  }
}

.infoList__footer {
  margin-top:rem(30);
  width:100%;
  padding:rem(20) rem(30);
  background:#f6f6f6;
  border-top:1px solid #eceaeb;
  p {
    font-size:rem(12);
    @include font("light");
    line-height:1.5;
  }
  p.infoList__mail {
    margin-top:rem(5);
    font-size:rem(14);
    @include font("medium");
    text-align:center;
    line-height:1.5;
  }
}