@charset "UTF-8";

.map {
  padding:0 30px;
  .global__subTit {
    font-size:rem(25);
    @include font();
    margin-bottom:rem(5);
    margin-top:rem(35)
  }
  &__api {
    width:100%;
    margin-bottom:rem(30);
    img {width:100%;vertical-align: top}
  }
  &__address,&__phone {
    font-size:rem(16);
    @include font("dLight");
    line-height:1.7
  }
  &__phone {
    margin-bottom:40px;
  }
  &__type {
    font-size:0;
    width:100%;
    @include before-middle();
    h3 {
      @include font();
      font-size:rem(16);
      margin-bottom:rem(10);
    }
    &Left,&Right {
      display:inline-block;
      vertical-align: middle;
    }
    &Right {
      font-size:rem(16);
      @include font("dLight");
    }
    &+& {
      margin-top:0;
    }
    &List {
      line-height:1.5;
      @include font("dLight");
      strong {@include font("medium");}
      &:before {
        top:10px !important;
      }
    }
  }
  .dotList {
    li:before {
      top:8px;
    }
  }
}

html:lang(en) {
    .map {
      .global__subTit {padding:0;margin-bottom:rem(20)}
      .dotList li:before {top:12px;}
    }
}