@charset "UTF-8";

@charset "UTF-8";

.infoMi {
  overflow: hidden;
  margin-bottom:35px;
  padding:0 30px;
  &__left {
    font-size:16px;
    @include font("dLight")
  }
  &__tit {
    font-size:25px;
    margin-bottom:20px;
    @include font("dLight");
    color:#313131;
  }
  &__txt {
    line-height:1.5;
    margin-top:15px;
    word-break: keep-all;
  }
  &__right {
    max-width:100%;
    vertical-align: top;
  }
}

.imgMi {
  display:inline-block;
  margin-bottom:30px;
  max-width:100%;
  padding:0 30px;
  margin-top:35px;
  vertical-align: top;
  &.-total {margin:0 0 30px 0;}
}

.infoMiDwn {
  &__tit {
    font-size:18px;
    @include font();
    padding:0 30px;
    margin-bottom:20px;
  }
  &__list {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding:0 30px;
    li {
      width:100%;
      &+li {margin-left:10px;}
    }
  }
  &__btn {
    width:100%;
    display:block;
    height:50px;
    line-height:50px;
    text-align:center;
    color:#fff;
    background:#5673bc;
    font-size:16px;
    border-radius: 15px 0 15px 0;
    i {display:inline-block;margin-right:5px;}
  }
}