@charset "UTF-8";
//color
$cBlue:#367df3;
html,body {width:100%;}
body {
  padding-top:rem(47.5);
}
.mainBody {
 padding-top:rem(87.5);
}
.layoutTop {
  &__logo {
    font-size:0;
    img {width:rem(181);vertical-align: top}
  }
  height:rem(47.5);
  margin:0 auto;
  position:fixed;
  top:0;
  background:#fff;
  width:100%;
  border-bottom:1px solid #d4d4d4;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &__menu {
    display:block;
    position:absolute;
    width:rem(25);
    height:rem(14);
    top:50%;
    transform: translateY(-50%);
    left:rem(30);
    font-size:0;
    span {
      display:block;
      position:absolute;
      width:100%;
      height:3px;
      background:#313131;
      top:0;
      left:0;
      font-size:0;
      transition: all .3s;
      &+span {
        top:5px;
        &+span {
          top:10px;
        }
      }
    }
    .menu--wrap {
      position:relative;
      height:100%;
    }
  }
}


.layout-footer {
  background:#f2f2f0;
  padding:rem(30);
  margin-top:rem(50);
  &__etcLink {
    width:100%;
    .selectBox {
      width:100%;
      margin-bottom:rem(15);
    }
  }
  &__contents {
    width:100%;
  }
  &__address {
  }
  &--copyright {
    //text-transform:uppercase;
    font-size:rem(12);
    color:#717171;
    margin-top:rem(5);
  }
  &--address {
    font-size:rem(12);
    color:#717171;
    @include font("dLight");
  }
}





//서브 페이지
.subContents {

}

//서브비쥬얼
.subVisual {
  width:100%;
  height:rem(60);
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
  text-align:center;
  @include before-middle();
  &__nav {
    display:none;
  }
  &__tit {
    display:inline-block;
    vertical-align: middle;
    color:#fff;
    font-size:rem(23);
    @include font("dLight");
  }
  &--bgVideo {background-image:url("/m/images/comm/bg_sub_visual_video.jpg")}
  &--bgDir {background-image:url("/m/images/comm/bg_subVisual_dir.jpg")}
  &--bgAtc {background-image:url("/m/images/comm/bg_subVisual_01.png");}
  &--bgMlrd {background-image:url("/m/images/comm/bg_subVisual_02.png");}
  &--bgOrgcht {background-image:url("/m/images/comm/bg_subVisual_03.png");}
  &--bgPropse {
    background-image:url("/m/images/comm/bg_subVisual_propse.jpg");
  };
  background-position:center top;
  &.-big {
    height:auto;
    &:before {display:none};
    color:#fff;
    text-align:left;
    padding:rem(35) rem(30);
    .subVisual {
      &__tit {
        font-size:15px;
        @include font("bold");
        padding-bottom:rem(5);
        border-bottom:2px solid #fff;
        display:inline-block;
        margin-bottom:rem(13);
      }
      &__subTit {
        font-size:rem(35);
        @include font();
        i {@include font("light");display:block;}
      }
      &__txt {
        font-size:rem(15);
        line-height:1.6;
        @include font("light");
        margin-top:rem(10);
        word-break: keep-all;
      }
      &__btn {
        display:block;
        margin-top:rem(20);
        width:rem(200);
        height:rem(50);
        color:#fff;
        text-align:center;
        border:2px solid #fff;
        line-height:rem(50);
        border-radius:15px 0 15px 0;
        font-size:rem(16);
      }
    }
  }
}



.fullGnb {
  max-width:100%;
  width:100%;
  position:absolute;
  top:80px;
  left:0;
  background:#fff;
  z-index:1000;
  border-bottom:1px solid #dce3e8;
  text-align:center;
  display:none;
  &__wrap {
    display:inline-block;
    text-align:left;
    padding:30px 0 50px;
    font-size:0;
  }
  &__tit {
    font-size:40px;
    @include font();
    display:inline-block;
  }
  &__group {
    display:inline-block;
    margin-left:60px;
    vertical-align: top;
    &.empty {
      margin-left:40px;
      .fullGnb__sub {color:#fff;opacity: 0}
    }
  }
  &__sub {
    font-size:22px;
    @include font("dLight");
    margin-bottom:17px;
    margin-top:3px;
  }
  &__link {
    font-size:16px;
    margin-bottom:8px;
    display:block;
    &--th {
      font-size:14px;
      margin-bottom:8px;
      a {color:#717171;}
      li {
        text-indent: -10px;
        padding-left:15px;
        &+li {margin-top:5px;}
        &:before  {
          content:"";
          display:inline-block;
          width:4px;
          height:4px;
          vertical-align: middle;
          background:#717171;
          margin-right:5px;
        }
      }
    }
  }
  &__menu {
    display:none;
    &.active {
      display:inline-block;
    }
  }
  &.active {
    display:block;
  }
  a {
    position:relative;
    transition: all .3s;
    &:hover {
      color:$cBlue;
    }
  }
}


html:lang(en) {
  .layoutTop__logo img {width:82px}
}




.error__tit {
  text-align:center;
  vertical-align: middle;
  @include before-middle();
  @include font();
  line-height:60px;
}
.errorIcon {
  display:inline-block;
  vertical-align: middle;
}

.error.-eng {
  .error {
    &__logo {
      img {width:82px;}
    }
    &__tit {
      color:#fff;
      vertical-align: middle;
      @include before-middle();
      img {
        width:27px;
        vertical-align: middle;
        display:inline-block;
        margin-top:0;
      }
      span {vertical-align: middle;line-height:60px;font-size:rem(24)}
    }
  }

}
