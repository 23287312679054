@charset "UTF-8";
//심의회의 산하 위원회
.subTit {
  text-align:left;
  color:#313131;
  font-size:rem(22);
  @include font();
  margin-top:rem(25);
}

.tabs {
  &.-comm {
    margin-bottom: 0;
  }
}
.tabsComm {
  width: 100%;
  background: #f6f6f6;
  border-bottom:2px solid #838a9c;
  overflow: hidden;
  height: 67px;
  position: relative;
  transition: all .3s;
  display: flex;
  flex-wrap:nowrap;
  flex-direction: column;
  &.-open {
    height:auto;
    &:after {
      transform: rotate(-180deg);
    }
  }
  &:after {
    content:"";
    display:block;
    position:absolute;
    right:30px;
    top:30px;
    transition: all .3s;
    @include iconSelect
  }
  &__item {
    width:100%;
    display:block;
    padding-left:rem(55);
    line-height:67px;
    font-size:rem(19);
    &.-active {
      order:-1;
      &:before {
        content:"";
        position:absolute;
        left:30px;
        top:20px;
        transition: all .3s;
        @include iconSubTab;
      }
    }
  }
}
.committee {
  width: 100%;
  margin-top:rem(30);
  padding:0 rem(30);
  &__tit {
    font-size: 30px;
    font-weight: normal;
    margin-top: 50px;
  }
  &__subTit {
    font-size: rem(25);
    font-weight: normal;
    margin-top: rem(30);
  }
  &__table {
    border-spacing: 0;
    width: 100%;
    margin-top: rem(19);
    text-align: center;
    font-size:rem(16);
    @at-root .orgcht .committee__table {padding:0}
    a {font-size:rem(16)}
    thead {
      tr:first-child {
        th {
          background: #2497a4;
          color: #fff;
          &:first-of-type {border-top-left-radius:10px 10px;}
          &:last-of-type {border-bottom-right-radius:10px 10px;}
          a {color:#fff;}
        }
        th ~ th {
          border-left:1px solid #d6d6d6;
        }
      }
      th {
        height: rem(30);
        text-align: center;
        background: #f6f6f6;
        @include font();

      }
      th + th {
        border-left: 1px solid #d6d6d6;
      }
    }
    tbody {
      th {@include font()}
      td {
        border-bottom:1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
        padding: rem(15) rem(10);
        &:first-child {
          border-left: 0;
        }
      }
      .descTxt {
        text-align: left;
        td {
          border-bottom: 0;
          border-left: 0;
        }
      }
    }
    &.-left {
      tbody tr td {
        text-align:left;
        &.center {text-align:center;padding-left:0}
      }
    }
    &.-leftArry {
      tbody tr {
        td,th {
          padding-left:10px;
          padding-right:10px;
          text-align:left;
        }
      }
    }
    &.-leftArryRight {
      tbody tr {
        td {
          &:last-of-type {
            text-align:left;
            padding-left:10px;
          }
        }
      }
    }
    &.-arry {
      tbody tr {
        td {
          text-align:left;
          padding-left:10px;
          padding-right:10px;
          &:last-of-type {text-align:center;}
        }
      }
    }
  }
  .bolList + &__subTit {
    margin-top: rem(35);
  }

  .bolList {
    margin-top: rem(20);
    &__item {
      position: relative;
      padding-left: 15px;
      font-size: 18px;
      line-height: 32px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 2px;
        top: 14px;
        width: 4px;
        height: 4px;
        background: #717171;
      }
    }
    &.-noStyle {
      .bolList__item {
        padding-left:0;
        &:before {display:none}
      }
    }
  }
  .iconArrowTit {
    display: inline-block;
    margin:0 10px;
  }
}

.txtCnt {
  text-align:center !important;
}

.cRed {color:#f06146;}
.guide {
  font-size:rem(13);
  @include font("light");
  padding:0 rem(30);
  margin-top:rem(30);
}



.relSite {
  padding:0 rem(30);
  .guide {
    padding:0;
  }
}
