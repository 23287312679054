@charset "UTF-8";

.infoList {
  clear:both;
  & + & {margin-top:30px}
  &.-top {
    width:100%;
    border-bottom:1px solid #eceaeb;
    padding:rem(45) rem(30) rem(25);
    overflow:hidden;
    position:relative;
    margin-top:35px;
    .infoList {
      &__tit {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        font-size:rem(23);
      }
      &__thum {
        width:100%;
        height:0;
        padding-top:(360/303*100%);
        border-radius:15px 0 15px;
        border:1px solid #d6d6d6;

      }
      &__subTit {
        margin-top:30px;
      }
      &__careerItem {
        font-size:rem(13);
      }
      &__subTit {
        font-size:rem(15);margin-top:rem(20);
      }
      &__type {
        font-size:rem(13);
        @include font("dLight");
        word-break: break-all;
        position:relative;
      }
    }
  }
  &__thum {
    width:100%;
    height:0;
    padding-top:(283/302*100%);
    overflow:hidden;
    background-size:cover;
    background-position:top center;
    background-repeat: no-repeat;
    background-color:#f6f6f6;
  }
  &__left {
    float:left;
    width:(300/630*100%);
    overflow:hidden;
  }
  &__right  {
    float:left;
    width:50%;
    padding-left:rem(15);
    padding-top:rem(1);
  }
  &__group {
    margin-top:-15px;
  }
  &__tit {
    font-size:rem(23);
    @include font("dLight");
    color:#3173e6;
    margin-bottom:20px;
    padding:0 30px;
  }
  &__name {
    font-size:rem(15);
    @include font("medium");
    margin-bottom:10px;
  }
  &__type {
    font-size:13px;
    @include font("dLight");
    word-break: break-all;
    letter-spacing: -0.035em;
  }
  &__subTit {
    font-size:15px;
    @include font("medium");
    margin:20px 0 10px;
  }
  &__careerItem {
    font-size:13px;
    @include font("dLight");
    word-break: break-all;
    position:relative;
    &:not(:first-child) {
      margin-top:5px;
    }

  }
  &__moreBtn {
    width:calc(100% - 20px);
    border:1px solid #313131;
    font-size:14px;
    height:40px;
    line-height:40px;
    text-align:center;
    display:block;
    color:#313131;
    border-radius:15px 0;
    transition: all .3s;
    position:absolute;
    left:50%;
    bottom:20px;
    transform: translateX(-50%);
    &:hover {
      border-radius:0 15px
    }
  }
  &__info {
    padding:0 10px 20px;
    position:relative;
    @at-root .infoList__item.-btn .infoList__info {padding-bottom:80px}
  }
  &__row {
    font-size:0;
    padding:0 30px;
    width:100%;
    display:flex;
    flex-flow:row wrap;
    justify-content: space-between;
  }
  &__item {
    width:(300/630*100%);
    border-radius:15px 0 15px;
    border:1px solid #d6d6d6;
    overflow:hidden;
    vertical-align: top;
    position:relative;
    margin-top:15px;
  }
  &__popupTit {
    font-size:rem(23);
    @include font();
    margin-bottom:10px;
  }
  @at-root .layerPopup {
    .infoList.-top {
      margin-bottom:30px;
      padding:60px 30px 25px;
      overflow:hidden;
      position:relative;
      .infoList {
        &__thum {
          padding-top:(287/300*100%);
        }
        &__careerItem {
          font-size:14px;
        }
        &__tit {
          font-size:20px;
          margin-bottom:5px;
          padding:0;
          position:initial;
          word-break: keep-all;
          letter-spacing: -1px;
        }
        &__popupTit {
          position:absolute;
          left:0;
          top:20px;
          padding:0 30px;
        }
      }
    }
  }
}

@media all and (max-width:320px) {
  .infoList__tit {font-size:20px;}
}


html:lang(en) {
  .intro__commt {font-size:rem(24);margin-top:rem(15)}
  .intro__orign {
    font-size:rem(11);line-height:1.5;
    margin-top:rem(20);
  }
  .intro__sub {
    font-size:rem(25);
    margin-top:rem(50);
  }
  .floatLeft .intro__sub {margin-top:0;}
  .intro__th {
    margin-top:rem(20);
    @include font("medium");
  }
  .infoList.-top {margin-top:rem(30);padding-top:0;}
  .infoList.-top .infoList__subTit {clear:both;}
  .global__subTit {
    padding:0 rem(30);margin-top:rem(25);font-size:rem(22);
    @include font();
  }
  .infoList__tit {font-size:rem(20)}
  .infoList.-top .infoList__tit {
    position:inherit;
    padding:0;
    font-size:rem(20);
    margin-bottom:rem(10);
  }
  .infoList__subTit {
    font-size:rem(14);
    margin-top:rem(15);
    letter-spacing: -0.012em;
    strong {display:block;}
  }
  .infoList__careerItem {font-size:rem(11);line-height:rem(14);padding-left:10px;word-break: keep-all}
  .infoList__careerItem:before {top:5px;width:3px;height:3px;}
  .infoList__name {font-size:rem(20);}
  .infoList__type {font-size:rem(11);word-break: keep-all}
  .infoList.-top .infoList__type {
    padding-left:0;
    line-height:rem(14);
    &:before {display:none;}
  }
  .infoList.-top .infoList__left {margin-bottom:rem(20)}
  .infoList__item.-leader {
    .infoList__subTit:first-of-type {
      @include font();
      strong {@include font("medium")}
    }
  }
  .infoList.-top .infoList__careerItem {word-break: keep-all}
}
