@charset "UTF-8";

.propse {
  &__info {
    margin-top:rem(30);
    font-size:rem(11);
  }
  &__infoItem {
    vertical-align: middle;
    text-indent:rem(-8);
    padding-left:rem(8);
    line-height:1.5;
    @include font("dLight");
    &:before {
      content:"";
      display:inline-block;
      width:2px;
      height:2px;
      background:#fff;
      vertical-align: middle;
      margin-top:-2px;
      margin-right:rem(5);
    }
  }
}



.propseSection {
  padding:0 rem(30);
  margin-top:rem(30);
  &.-brd {
    border-bottom:1px solid #ededed;
    padding-bottom:rem(20);
    margin-bottom:rem(20);
  }
  &__tit {
    font-size:19px;
    @include font();
    color:#313131;
    display:flex;
    flex-flow: row nowrap;
    align-items:center;
    margin-bottom:10px;
  }
  &__guide {
    font-size:rem(12);
    @include font("light");
    color:#313131;
    margin-left:rem(5);
  }
  &__info {
    width:100%;
    margin-top:15px;
    text-align:center;
  }
  &__txt {
    font-size:rem(12);
    color:#313131;
    margin-bottom:rem(10);
  }
  &__chkInp:checked+.propseSection__chkLabel:after {
    content:"";
    position:absolute;
    left:5px;
    top:7px;
    width:10px;
    height:10px;
    background:#5673bc;
    border-radius:50%;
    font-size:0;
    margin-top:-2px;
  }
  &__chkLabel {
    font-size:rem(16);
    position:relative;
    padding-left:25px;
    display:inline-block;
    line-height:20px;
    @include font("medium");
    &:before {
      content:"";
      display:block;
      position:absolute;
      left:0;
      width:18px;
      height:18px;
      border:1px solid #363636;
      border-radius:50%;
      font-size:0;
    }
  }
  &+.btnGroup {margin-top:rem(30)}
  &__table {
    width:100%;
    border-collapse: collapse;
    border-top:2px solid #3173e6;
    th {
      background:#f6f6f6;
      font-size:rem(16);
      text-align:left;
      height:60px;
      vertical-align: middle;
      @include font("dLight");
    }
    thead {
      th {
        text-align:center;
        border-right:1px solid #dfe0e1;
        border-bottom:1px solid #dfe0e1;
        &:last-child {
          border-right:none;
        }
      }
    }
    tbody {
      th {
        text-align:left;
        padding-left:rem(10);
        border-bottom:1px solid #dfe0e1;
        .cRequired {
          margin-left:-15px;
        }
      }
      td {
        border-right:1px solid #dfe0e1;
        border-bottom:1px solid #dfe0e1;
        height:60px;
        padding:10px 15px;
        font-size:rem(16);
        @include font("light");
        &:last-child {
          border-right:none;
        }
        input {
          height:39px;
          line-height:39px;
          padding:0 10px;
          width:100%;
          border:1px solid #c5c7c9;
          &[type="checkbox"] {
            height:auto;
            line-height:1;
            padding:0 5px 0 0;
            width:auto;
            border:none;
          }
          &[type="file"] {
            height:auto;
            line-height:1;
            padding:5px 0;
            width:90%;
          }
        }
      }
    }
    &.-center {
      td {text-align:center;padding:0;}
    }
  }
  &.-mobile {
    .propseSection__tit {
      color:#3173e6
    }
    .propseSection__table {
      border:none;
      display:block;
      width:100%;
      tbody {
        display:block;
        tr {
          display:block;
          &:first-of-type {
            th:first-of-type {margin-top:0px}
          }
          th {
            display:block;
            background:none;
            height:auto;
            border:none;
            padding-left:0;
            margin-top:rem(20);
            .cRequired {margin-left:0}
          }
          td {
            display:block;
            height:auto;
            border:none;
            padding:0;
            margin-top:rem(10);
          }
        }
      }
    }
  }
}

.cRequired {
  color:#f06146;
  @include font();
  &.-error {
    font-size:14px;
    margin:rem(5) 0 rem(10) 0;
    float:left;
    width:100%;
    color:#f06146;
  }
}

.checkLabelList {
  width: 100%;
  margin: 20px 0 0 0;
  float: left;
  color: #717171;
  &:last-of-type {
    margin-bottom:rem(20);
  }
  &__dd {
    font-size: rem(14);
    margin-top: rem(5);
    @include font("dLight")
  }
  .propseSection {
    &__chkInp:checked + label {
      color: #313131;
      @include font("medium");
      & + .checkLabelList__dd {
        color: #313131
      }
      &:after {
        top: 7px
      }
    }
  }
}

.txtArea {
  width:100%;
  height:100px;
  overflow-y:auto;
  resize:none;
  border:1px solid #c5c7c9;
  padding:10px 10px 20px 10px;
  &__count {
    text-align:right;
    position:relative;
    margin-top:5px;
    margin-bottom:5px;
    left:-5px;
    font-size:rem(11);
    @include font("light")
  }
}

.inpFile {
  &__btn {
    display:inline-block;
    width:114px;
    line-height:40px;
    vertical-align: middle;
    height:40px;
    color:#fff;
    background:#9e9e9e;
    text-align:center;
    border-radius:15px 0 15px;
  }
  &__txt {
    font-size:rem(12);
    line-height:1.5;
    @include font("light");
    display:block;
    margin-top:rem(10);
  }
  &__del {
    display:inline-block;
    width:18px;
    img {width:100%}
  }
  &__addTxt {
    display:inline-block;
    font-size:18px;
    @include font("dLight");
  }
  &__add {
    margin-top:15px;
    &.remove {
      display:none;
    }
    &:first-of-type {
      margin-top:20px;
    }
  }
}

.propseCompleted {
  text-align:center;
  margin-top:rem(45);
  &__tit {
    font-size:rem(26);
    @include font("light");
    color:#3173e6;
    line-height:1;
    &:before {
      content:"";
      display:block;
      width:63px;
      height:63px;
      background:url("/m/images/prpare/img_complete.png") no-repeat 0 0;
      background-size:cover;
      margin:0 auto rem(15);
    }
  }
  &__txt {
    margin-top:rem(15);
    font-size:rem(16);
    margin-bottom:rem(38);
    @include font("dLight")
  }
}

.propse__view {
  &.-tit {
    font-size:rem(19);
    color:#3173e6;
    border-bottom:2px solid #3173e6;
    display:inline-block;
    margin-bottom:rem(15);
    line-height:1;
    @include font("dLight");
    &:after {
      content:"";
      display:block;
    }
  }
  &.-txt {
    margin-bottom:rem(25);
    @include font("dLight");
    font-size:rem(16);
    p:not(:first-of-type) {
      margin-top:rem(25);
    }
  }
}

.propseAnswer {
  margin-top:35px;
  border:1px solid #2497a4;
  border-radius:10px;
  padding:25px 15px;
  position:relative;
  font-size:rem(16);
  &:before {
    content:"";
    display:block;
    position:absolute;
    width:16px;
    height:13px;
    background:#fff url("/m/images/prpare/img_top_arrow.png") no-repeat 0 0;
    background-size:cover;
    top:-12px;
    left:60px;
  }
  &__tit {
    font-size:25px;
    color:#2497a4;
    line-height:1;
    margin-bottom:20px;
    @include font();
    &:before {
      content:"";
      display:inline-block;
      width:24px;
      height:22px;
      background:url("/m/images/prpare/iconReplay.png") no-repeat 0 0;
      background-size:cover;
      margin-right:5px;
      position:relative;
      top:2px;
    }
  }
  &__txt {
    p:not(:first-of-type) {
      margin-top:rem(20);
    }
  }
}

.mgTop60 {margin-top:60px}


.captcha {
  overflow: hidden;
  &__img {
    width:100%;
    img {width:100%;height:auto !important;vertical-align: top}
  }
  &__right {
    float:right;
    width:calc(100% - 253px);
  }
  &__inp {
    width:100% !important;
    height:45px !important;
    margin-top:10px;
  }
  &__btn {
    width:100%;
    height:50px;
    display:inline-block;
    background:#9e9e9e;
    color:#fff;
    margin-top:5px;
  }
  &__guide {
    font-size:14px;
    @include font("dLight");
    margin-top:15px;
  }
}