@charset "UTF-8";

@import "/font/NotoKR-Black/stylesheet.css";
@import "/font/NotoKR-Bold/stylesheet.css";
@import "/font/NotoKR-Regular/stylesheet.css";
@import "/font/NotoKR-Medium/stylesheet.css";
@import "/font/NotoKR-Light/stylesheet.css";
@import "/font/NotoKR-DemiLight/stylesheet.css";
@import "/font/NotoKR-Thin/stylesheet.css";


@import "common/reset";
@import "common/function";
@import "common/mixin";

@import "vendor/sprite";

@import "common/layout";
@import "common/common";
@import "common/gnb";
@import "common/tabs";
@import "common/layerPopup";
@import "common/grayBox";

//페이지
@import "page/main";
@import "page/bbs";
@import "page/intro";
@import "page/orgcht";
@import "page/infoList";
@import "page/committee";
@import "page/greeting";
@import "page/hist";
@import "page/map";
@import "page/propse";
@import "page/infoMi";

//오류수정
@import "page/browerFix";
