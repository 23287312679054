@charset "UTF-8";

.tabs {
  width:100%;
  border-bottom:2px solid #3173e6;
  overflow:hidden;
  height:67px;
  position:relative;
  transition: all .3s;
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  &.-open {
    height:auto;
    &:after {
      transform: rotate(-180deg);
    }
  }
  &:after {
    content:"";
    display:block;
    position:absolute;
    right:30px;
    top:30px;
    transition: all .3s;
    @include iconSelect
  }
  &__item {
    width:100%;
    display:block;
    padding:0 30px;
    line-height:67px;
    background:#fff;
    font-size:19px;
    &.-active {
      background:#e7edf1;
      color:#3173e6;
      order:-1;
    }
  }
}