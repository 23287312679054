@charset "UTF-8";
.greeting {
  @include clearFix();
  margin-top:rem(20);
  padding:0 rem(30);
  &__tit {
    padding:0 rem(30);
    font-size:rem(25);
    margin-top:rem(30);
    @include font("dLight");
    &.-blue {
      color:#3d7eeb;
      margin-top:rem(5);
    }
  }
  &__right {
    margin:0 auto;
    margin-bottom:rem(35);
    width:rem(200);
    img {width:100%;}
  }
  &__txt {
    font-size:rem(16);
    @include font("dLight");
    line-height:1.8;
    word-break: keep-all;
    &:not(:first-of-type) {
      margin-top:rem(20);
    }
  }
  &__thum {vertical-align: top}
  &__thumTxt {
    font-size:rem(15);
    @include font("medium");
    text-align:center;
    margin-top:rem(10);
    small {
      font-size:rem(12);
      @include font();
    }
  }
}

html:lang(en) {
  .greeting__tit {
    font-size:rem(24);
    @include font("light");
  }
  .greeting__thumTxt small {font-size:rem(10)}
  .greeting__thumTxt {text-align:left}
}