@charset "UTF-8";
.gnb {
  position:fixed;
  width:calc(100% - 5.5rem);
  height:100%;
  background:#e7eaee;
  left:-100vw;
  transition: all .3s;
  top:0;
  z-index: 2500;
  min-width:300px;
  &__wrap {
    position:relative;
    overflow-y:auto;
    height:100%;
  }
  &__tit {
    background:#e7eaee;
    line-height:rem(47);
    font-size:rem(15);
    @include font("medium");
    padding-left:rem(30);
  }
  &__dep01 {
    font-size:rem(18);
    @include font("bold");
    border-top:1px solid #dcdcdc;
    height:rem(67);
    display:flex;
    align-items: center;
    padding:0 rem(30);
    a {display:block;width:100%}
    &.active {
      a {color:$cBlue;}
      &+.gnb__group {
        border-top:1px solid #3173e6;
      }
    }
  }
  &__dep02 {
    padding:rem(25) rem(30) rem(25);
    align-items: center;
    font-size:rem(18);
    display:flex;
    border-bottom:1px solid #dcdcdc;
    a:before {
      content:"";
      display:inline-block;
      vertical-align: baseline;
      opacity: 0;
      width:rem(16);
      margin-right:rem(15);
    }
    a[href="#this"] {
      &:before {
        @extend .iconPlus;
        position:relative;
        top:rem(1);
        margin-right:rem(15);
        opacity: 1;
      }
    }
    &+.gnb__group {
      border-bottom:1px solid #dcdcdc;

    }
    &:last-of-type {border-bottom:none;}
    &.active {
      border-bottom:none;
      a[href="#this"] {
        &:before {
          @extend .iconMiner;
        }
      }
    }
  }
  &__link {
    padding:0 rem(30) rem(30) rem(61);
    display:block;
    font-size:rem(18);
    @include font("dLight");
    background:#fff;
    &--th {
      padding-left:rem(61);
      margin-bottom:rem(25);
      a {
        color:#717171;
        font-size:rem(16);
        @include font("dLight");
      }
      li {
        text-indent: rem(-10);
        padding-left:rem(15);
        &+li {margin-top:rem(25);}
        &:before  {
          content:"";
          display:inline-block;
          width:rem(4);
          height:rem(4);
          vertical-align: middle;
          background:#717171;
          margin-right:rem(5);
          position:relative;
          top:rem(-3);
        }
      }
    }
  }
  &__util {
    padding:0 rem(30);
    overflow:hidden;
    line-height:rem(67);
    height:rem(67);
    width:100%;
    border-top:1px solid #dcdcdc;
    border-bottom:1px solid #dcdcdc;
  }
  &__sns {
    display:block;
    color:$cBlue;
    float:left;
    height:100%;
    width:50%;
    vertical-align: middle;
    font-size:rem(16);
    border-right:1px solid #ccc;
    span {
      display:inline-block;
      vertical-align: middle;
      margin-right:rem(10);
    }
  }
  &__lang {
    display:block;
    color:$cBlue;
    line-height:rem(67);
    font-size:rem(16);
    float:left;
    width:50%;
    &:before {
      content:"";
      display:inline-block;
      vertical-align: middle;
      margin-right:rem(10);
      @extend .iconEng;
    }
  }
  &__close {
    position:absolute;
    right:rem(30);
    top:rem(16);
    display:block;
    @extend .iconXblack;
    z-index:1;
  }
  &__group {
    display:none;
    background:#fff;
  }
  .active {
    background:#fff;
  }
  .active+.gnb__group {
    display:block;
  }
  .active>.gnb__group {
    display:block;
  }
}

.activeMenu {
    /*width:100%;
    height:100%;
    position: fixed;
    overflow:hidden;
    left:0;top:0;*/

  .gnb {
    left:0;
  }
  .backgroundBG {
    opacity: 1;
    visibility: visible;
  }
}

.backgroundBG {
  position:fixed;
  left:0;
  top:0;
  bottom:0;
  right:0;
  background:rgba(0,0,0,0.7);
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  overflow:hidden;
}


html:lang(en) {
  .gnb__dep02 {@include font("medium");padding-left:0}
}