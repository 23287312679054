@charset "UTF-8";
//조직도
.orgcht {
  text-align:left;
  width:100%;
  margin-top:rem(30);
  padding:0 rem(30);
  &__tit {
    font-size:rem(25);
    @include font();
  }
  &__img {
    width:100%;
    max-width:630px;
  }
  &__nmpr {
    margin-top:rem(10);
    font-size:0;
    @include font("light");
    span {
      display:inline-block;
      font-size:rem(20);
      &:first-of-type {display:block;margin-bottom:rem(5)}
      &:last-of-type {margin-left:rem(15)}
    }
  }
  &__img {
    text-align:center;
    vertical-align: top;
    margin-top:rem(15);
    width:100%;
    img {
      width:100%;
      max-width:630px;
    }
  }
  &__table {
    width:100%;
    border-spacing: 0;
    overflow:hidden;
    margin-top:rem(10);
    thead {
      th {
        background:#2497a4;
        color:#fff;
        height:40px;
        text-align:center;
        font-size:rem(16);
        &:first-of-type {border-radius:10px 0 0 0;}
        &:last-of-type {border-radius:0 0 10px 0;}
      }
    }
    tbody {
      td {
        text-align:center;
        height:rem(25);
        font-size:rem(16);
        border-bottom:1px solid #d6d6d6;
      }
    }
  }
}

.subTit {
  text-align:left;
  color:#313131;
  font-size:rem(22);
  @include font("medium");
  margin-top:rem(25);
}

html:lang(en) {
  .orgcht {
    text-align:center;
    .orgcht__tit {font-size:rem(22);@include font("dLight")}
    .subTit {margin-top:rem(40);@include font("dLight")}
  }
  .orgcht__nmpr {margin-top:0;text-align:left;}
  .orgcht__tit {font-size:rem(24);@include font();text-align:left}
  .orgcht__nmpr {font-size:rem(19);display:block;line-height:1.4;}
  .orgcht__nmpr span:first-of-type {margin-bottom:0;margin-top:rem(20)}
  .orgcht__nmpr span:last-of-type {margin-left:0;}
  .committee__table thead th {
    font-size:rem(14);
  }
  .committee__table tbody td {font-size:rem(14)}
}

