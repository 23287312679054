@charset "UTF-8";
//공통
legend {@include txtHidden()}
label {cursor:pointer}
//color

//설렉트박스
.selectBox {
  border:1px solid #dadbdc;
  display:inline-block;
  vertical-align: middle;
  background:#fff;
  label {
    display:inline-block;
    font-size:rem(16);
    @include font("light");
    margin:0 rem(24) 0 rem(10);
    width:75%;
    @include ellipsis();
  }
  select {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
    opacity: 0;
    font-size:rem(16);
    @include font("light");
  }
  &__wrap {
    position:relative;
    &:after {
      content:"";
      display:block;
      @extend .bg_select;
      position:absolute;
      right:rem(13);
      top:50%;
      transform: translateY(-50%);
    }
  }
  &--link {
    label {
      line-height:rem(40);
      vertical-align: top;
      position:relative;
      top:1px;
    }
  }
  &__link {

  }
}

//검색양식
.searchBox {
  overflow:hidden;
  font-size:0;
  height:rem(64);
  width:100%;
  padding:0 rem(30);
  border-bottom:1px solid #dce3e8;
  form,fieldset {width:100%;height:100%}
  &__flex {
    display:flex;
    align-items: center;
    height:100%;
    width:100%;
    justify-content: space-between;
  }
  &__inp {
    border:none;
    flex-grow:1;
    padding-right:rem(10);
    font-size:rem(16);
    @include font("dLight");
    vertical-align: sub;
    line-height:rem(40);
    height: rem(40);
  }
  &__submit {
    display:block;
    @extend .btnSearch;
  }
  &__selectBox {
    border:none;
    margin-right:rem(5);
    label {
      line-height:rem(40);
      text-align:left;
      margin-left:0;
      padding-left:rem(5);
      @include font("dLight");
      width:auto;
    }
    select {padding-right:rem(10)}
    .selectBox__wrap:after {right:rem(5)}
  }
}




//paging
.paging {
  text-align:center;
  vertical-align: middle;
  position:relative;
  font-size:0;
  margin-top:rem(20);
  a {
    display:inline-block;
    width:40px;height:40px;line-height:40px;
    font-size:rem(13);
    color:#717171;
    text-align:center;
    vertical-align: middle;
    border:1px solid #d4d4d4;
    border-right:none;
    span {
      line-height:40px;
    }
    &:last-of-type {border-right:1px solid #d4d4d4;}
    &.active {
      background:#5673bc;
      border-color:#5673bc;
      color:#fff;
    }
  }
  [class*="icon"] {
    overflow:hidden;
    text-indent:-100%;
    display:inline-block;
    font-size:0;
  }
  &+.btnGroup {
    margin-top:rem(30);
  }
  &+.layout-footer {

  }
}

@media all and (max-width:320px) {
  .paging a {width:30px}

}

//버튼그룹
.btnGroup {
  font-size:0;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:0 rem(30);
  flex-wrap:wrap;
  &.-half {
    .btnGroup__btn {
      width:49%;
    }
  }
  &__btn {
    width:100%;
    line-height:rem(50);
    text-align:center;
    border:1px solid #313131;
    font-size:rem(16);
    border-radius:15px 0;
    display:inline-block;
    &.-info {
      margin:20px auto;
      width:calc(100% - 60px);
      display:block;
    }
  }
  &--long {
    padding:0 rem(25);
    min-width:100%;
  }
  &--long+&--long {
    margin-top:rem(10);
  }
}

.btn {
  &--blue {
    background:#5673bc;
    color:#fff;
    border-color:#5673bc;
  }
}


//버튼공통 효과
[class*="btn"] {
  transition: all 300ms;
  &:hover {
    border-radius:0 15px;
  }
}



//에러페이지

.error {
  text-align: center;
  &__logo {
    padding: 15px 30px;
    text-align: left;
    img {
      width: 181px;
      height: 16px;
    }
  }
  &__tit {
    background: #095db1;
    height: 60px;
    text-align: center;
    width: 100%;
    img {
      width: 170px;
      margin-top: 15px;
    }
  }
  &__con {
    margin-top: 40px;
    padding: 0 30px;
  }
  &__text {
    color: #717171;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    &.-em {
      font-size: 27px;
      line-height: 35px;
      font-weight: 400;
    }
    &.-mt22 {
      margin-top: 22px;
    }
  }

  .errorBtn {
    width: 152px;
    height: 60px;
    display: inline-block;
    font-size: 16px;
    border-radius: 15px 0;
    padding-top: 20px;
    margin: 45px 3px 40px;
    &.-white {
      background: #fff;
      border: 1px solid #313131;
      color: #313131;
    }
    &.-blue {
      background: #5673bc;
      border: none;
      color: #fff;
    }
  }
}

html:lang(en) {
  .layout-footer--address {
    letter-spacing: -0.5px;
    line-height:1.5;
  }
  .layout-footer--copyright {
    margin-top:0;
    line-height:1.5;
  }
}

.prepare {
  .mainPosition {position:initial}
  .layoutTop {
    justify-content:flex-start;
    padding-left:rem(30);
  }
  &__tit {
    height:60px;
    display:flex;
    justify-content: center;
    align-items: center;
    background:#095db1;
    color:#fff;
    font-size:23px;
    @include font();
    img {width:31px;display:inline-block;margin-right:5px;}
  }
  &__txt {
    padding:0 rem(30);
    font-size:rem(27);
    @include font("dLight");
    text-align:center;
    margin:40px 0 20px 0;
    line-height:1.4;
  }
  &__smTxt {
    font-size:rem(20);
    @include font("light");
    text-align:center;
    padding:0 rem(30);
    line-height:1.5;
  }
}