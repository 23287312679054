@charset "UTF-8";

.topBanner {
  width:100%;
  height:rem(40);
  overflow:hidden;
  position:relative;
  &+.backgroundBG+.layoutTop {top:40px}
  &__close {
    display:block;
    position:absolute;
    top:50%;
    right:rem(15);
    transform: translateY(-50%);
    @extend .iconX;
  }
  &__btn {
    border:1px solid #fff;
    color:#fff;
    position:absolute;
    right:170px;
    font-size:15px;
    top:50%;
    width:180px;
    margin-top:-5px;
    line-height:50px;
    text-align:center;
    border-radius:15px 0;
  }
  &__inner {
    width:1200px;
    margin:0 auto;
  }
  &__item {
    width:100%;
    height:rem(40);
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    display:block;
  }
  .bx-pager {
    position:absolute;
    z-index:1000;
    bottom:14px;
    left:0;
    width:100%;
    text-align:center;
    font-size:0;
  }
  .bx-pager-item {
    display:inline-block;
    &+.bx-pager-item {
      margin-left:8px;
    }
  }
  .bx-pager-link {
    display:block;
    @include txtHidden();
    border:1px solid #fff;
    border-radius:50%;
    width:8px;
    height:8px;
    &.active {
      background:#fff;
    }
  }
}

.mainNav {
  position:fixed;
  right:0;
  top:50%;
  transform: translateY(-50%);
  overflow:hidden;
  &__item {
    display:block;
    color:#fff;
    text-align:center;
    font-size:13px;
    width:40px;
    line-height:40px;
    border-bottom:1px solid #fff;
    background:#c3ced9;
    float:right;
    clear:both;
    transition: all .3s;
    span {
      display:none;
      transition: all .3s;
    }
    &:last-child {border-bottom:none}
    &.active {
      padding:0 20px;
      width:auto;
      background:#3d7eeb;
      border-bottom:1px solid #3d7eeb;
      span {
        display:inline;
      }
    }
  }
}

.mainBanner {
  width:100%;
  height:calc(100vh);
  overflow:hidden;
  position:relative;
  &__wrap {
    width:100%;
    height:100%;
  }
  .bx-viewport, .bx-wrapper{
    position:relative;
    width:100%;
    height:100% !important;
    top:0;
    left:0;
  }
  &__inner {
    display:block;
    vertical-align: middle;
    margin-top:rem(50);
    padding:0 rem(30);
  }
  &__tit {
    font-size:rem(20);
    @include font("bold");
    padding-bottom:rem(5);
    border-bottom:2px solid #fff;
    display:inline-block;
  }
  &__sub {
    font-size:rem(34);
    @include font("light");
    margin-top:rem(20);
  }
  &__btn {
    border:1px solid #fff;
    color:#fff;
    text-align:center;
    display:block;
    width:100%;
    line-height:rem(50);
    border-radius:15px 0;
    font-size:rem(16);
    margin-top:rem(23);
  }
  &__item {
    width:100%;
    height:100vh;
    font-size:0;
    position:relative;
    text-align:center;
    &.item01 {
      text-align:center;
      color:#fff;
      overflow:hidden;
      background:url("/m/images/main/bg_mainBanner__item_01.jpg") no-repeat center center;
      background-size:cover;
      .mainBanner__inner {
        padding:0;
      }
      .mainBanner__sub {
        text-transform: uppercase;
      }
    }
    &.item02 {
      background:url("/m/images/main/bg_mainBanner__item_02.jpg") no-repeat center center;
      background-size:cover;
      text-align:left;
      .mainBanner {
        &__inner {
          position:absolute;
          left:0;
          top:0;
          width:100%;
        }
        &__tit {color:#fff;}
        &__sub {
          color:#fff;
          font-size:rem(27);
        }
      }
    }
    &.item03 {
      background:url("/m/images/main/bg_mainBanner__item_03.jpg") no-repeat center center;
      background-size:cover;
      text-align:left;
      .item03 {
        display:inline-block;
        width:(360/375*100%);
        margin-top:10px;
      }
      .mainBanner {
        &__inner {
          position:absolute;
          left:0;
          top:0;
          text-align:center;
          width:100%;
          min-height:600px;
        }
        &__tit {
          color:#fff;
          border-color:#fff;
        }
        &__sm {
          font-size:rem(35);
          color:#1f9ea9;
          @include font("light");
          margin-top:rem(20);
        }
        &__th {
          font-size:rem(13);
          color:#313131;
          margin-top:rem(5);
          line-height:1.5;
          @include font("light");
          strong {
            @include font("medium");
          }
        }
      }
    }
    &.item04 {
      background:url("/m/images/main/bg_mainBanner__item_04.jpg") no-repeat center bottom;
      background-size:cover;
      .mainBanner {
        &__inner {
          position:absolute;
          left:0;
          top:0;
          text-align:left;
          width:100%;
        }
        &__tit {
          color:#313131;
          border-color:#313131;
        }
        &__sm {
          font-size:rem(27);
          color:#1f9ea9;
          @include font("light");
          margin-top:rem(20);
        }
        &__th {
          font-size:rem(13);
          color:#313131;
          margin-top:rem(5);
          line-height:1.5;
          @include font("light");
          strong {
            @include font("medium");
          }
        }
        &__btn {
          border-color:#313131;
          color:#313131;
        }
      }
    }
    &--video {
      position:absolute;
      left:50%;
      top:50%;
      min-width:100%;
      min-height:100%;
      width:auto;
      height:auto;
      z-index:-100;
      transform: translate(-50%,-50%);
    }
  }
  &__scroll {
    position:absolute;
    left:50%;
    margin-left:-39px;
    bottom:20px;
    width:78px;
    height:78px;
    text-align:center;
    background:#c6c6c6;
    font-size:0;
    border-radius:50%;
    opacity: .7;
    span {
      @include txtHidden();
      position:relative;
      top:20px;
      animation: scrollAnimate 1.5s infinite;
    }
  }

  .bx-pager {
    position:absolute;
    z-index:1000;
    bottom:rem(80);
    left:0;
    width:100%;
    text-align:center;
    font-size:0;
  }
  .bx-pager-item {
    display:inline-block;
    &+.bx-pager-item {
      margin-left:8px;
    }
  }
  .bx-pager-link {
    display:block;
    @include txtHidden();
    background:#7e8b9a;
    border-radius:50%;
    width:9px;
    height:9px;
    &.active {
      background:#3d7eeb;
    }
  }
}
@keyframes scrollAnimate {
  0% {top:18px}
  50% {top:28px}
  100% {top:18px;}
}


.bgVr {
  position:absolute;
  bottom:rem(0);
  left:50%;
  width:rem(237);
  transform: translateX(-50%);
  height:rem(188);
  background:url("/m/images/main/bg_vr.png") no-repeat bottom center;
  background-size:cover;
}

.mainPosition {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  z-index:2000;
  background:#fff;
}

.mainWork {
  width:100%;
  position:relative;
  padding:0 rem(30) rem(50) rem(30);
  margin-bottom:rem(30);
  border-bottom:1px solid #ebebeb;
  &__item {
    width:100%;
    overflow:hidden;
  }
  &__txtArea {
    width:100%;
    padding:rem(28) 0;
  }
  &__img {
    height:0;
    padding-top:(470/750*100%);
    overflow:hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
  }
  &__tit {
    font-size:rem(20);
    @include font("bold");
    color:#313131;
    height:rem(30);
    border-bottom:1px solid #313131;
    display:inline-block;
  }
  &__sub {
    color:#313131;
    font-size:rem(27);
    @include font("light");
    margin-top:rem(20);
  }
  &__group {
    display:none;
  }
  &__btn {
    margin-top:rem(25);
    border:1px solid #fff;
    border-radius:15px 0;
    color:#fff;
    text-align:center;
    font-size:rem(16);
    line-height:rem(50);
    display:block;
    width:100%;
  }
  &__slider {
    height:100%;
    width:100%;
    overflow:hidden;
  }
  .bx-viewport, .bx-wrapper{
    position:relative;
    width:100%;
    height:100% !important;
    top:0;
    left:0;
  }
  .bx-pager {
    position:absolute;
    z-index:1000;
    bottom:rem(-20);
    left:0;
    width:100%;
    text-align:center;
    font-size:0;
  }
  .bx-pager-item {
    display:inline-block;
    &+.bx-pager-item {
      margin-left:8px;
    }
  }
  .bx-pager-link {
    display:block;
    @include txtHidden();
    background:#7e8b9a;
    border-radius:50%;
    width:9px;
    height:9px;
    &.active {
      background:#3d7eeb;
    }
  }
}

.mainAdir {
  &__tit {
    font-size:rem(20);
    @include font("bold");
    color:#313131;
    height:rem(30);
    border-bottom:1px solid #313131;
    margin-left:rem(30);
    display:inline-block;
  }
  &__subTit {
    font-size:rem(27);
    @include font("light");
    margin:rem(22) 0 rem(15) rem(30);
  }
  &__bg {
    display:block;
    width:100%;
  }
  &__item {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width:100%;
    padding:rem(25) rem(30);
    align-items:center;
    border-bottom:1px solid #ebebeb;
  }
  &__thum {
    min-width:55px;
    width:55px;
    height:55px;
    border-radius:50%;
    background-position: center center;
    background-size:cover;
    margin-right:rem(14);
  }
  &__link {
    @include iconPlus();
    min-width:16px;
    margin-left:rem(15);
  }
  &__name {
    font-size:rem(20);
    @include font("dLight");
  }
  &__txt {
    font-size:rem(14);
    @include font("light");
    @include ellipsis_multi();
    margin-top:rem(5);
  }
}

.mainMrfn {
  width:100%;
  height:rem(500);
  background:url("/m/images/main/bg_main_04.jpg") no-repeat center center;
  background-size:cover;
  &__btn {
    width:100%;
    line-height:rem(50);
    text-align:center;
    border:1px solid #fff;
    border-radius:15px 0;
    display:block;
    margin-top:rem(25);
    font-size:rem(16);
    color:#fff;
  }
  &__wrap {
    display:inline-block;
    vertical-align: middle;
    color:#313131;
    text-align:left;
    padding:rem(40) rem(30);
    width:100%;
    h1 {
      font-size:rem(20);
      @include font("bold");
      height:rem(29);
      display:inline-block;
      color:#fff;
      border-bottom:1px solid #fff;
    }
    h2 {
      font-size:rem(27);
      margin:rem(17) 0 0 0;
      @include font("light");
      color:#fff;
    }
  }
}

.mainCnrs {
  width:100%;
  margin-top:rem(30);
  &__tit {
    font-size:rem(20);
    color:#313131;
    padding-bottom:rem(5);
    border-bottom:1px solid #313131;
    display:inline-block;
    @include font("bold");
    margin-left:rem(30);
  }
  &__item {
    display:block;
    padding:rem(25) rem(30);
    border-top:1px solid #ededed;
    &:last-of-type {border-bottom:1px solid #ededed;}
    &.-video {
      display:flex;
      flex-wrap:nowrap;
      justify-content:space-between;
    }
  }
  &__subTit {
    font-size:rem(17);
    @include font("dLight");
    @include ellipsis_multi();
    margin-bottom:rem(5);
  }
  &__type {
    display:inline-block;
    min-width:rem(60);
    height:rem(20);
    line-height:rem(20);
    text-align:center;
    padding:0 7px;
    vertical-align: middle;
    color:#fff;
    font-size:rem(12);
    margin-right:5px;
    &.-red {background:#e15a45;}
    &.-orange {background:#e69d26;}
    &.-blue {background:#2f6399;}
    &.-green {background:#a0ac2b}
  }
  &__thum {
    width:(200/750*100%);
    min-width:(200/750*100%);
    margin-left:rem(5);
    height:0;
    padding-top:(134/750*100%);
    background-size:cover;
    background-position:center center;
    background-repeat: no-repeat;
    position:relative;
    &:after {
      content:"";
      @include iconPlay();
      position:absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
    }
  }
  &__data {
    font-size:rem(12);
    color:#717171;
  }
}

.mainLocation {
  width:100%;
  margin-top:rem(40);
  &__map {
    width:100%;
    height:0;
    padding-top:(700/750*100%);
    background:url("/m/images/main/bg_main_map.jpg") no-repeat center center;
    background-size:cover;
  }
  &__info {
    padding:rem(26) rem(30);
    address {
      font-size:0;
      span {
        display:block;
        font-size:rem(16);
        @include font("light");
        color:#313131;
        line-height:1.5;
      }
    }
  }
  &__btn {
    border:1px solid #313131;
    width:100%;
    line-height:rem(50);
    border-radius:15px 0;
    text-align:center;
    margin-top:rem(23);
    display:block;
    font-size:16px;
    span {
      display:inline-block;
      vertical-align: middle;
      margin-right:rem(5);
      position:relative;
      top:-2px;
    }
  }
  &__link {
    margin-top:50px;
    width:100%;
    border-top:1px solid #e8e8e8;
  }
  &__inner {
    width:1200px;
    height:90px;
    margin:0 auto;
    @include before-middle();
    position:relative;
    h2 {
      display:inline-block;
      vertical-align: middle;
      font-size:20px;
      @include font("dLight");
      color:#313131;
    }
  }
  &+.layout-footer {margin-top:0;}
}

.engPopup {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 2000;
  top: 0; left: 0;
  text-align: center;
  display: none;
  overflow-y:auto;
  &__content {
    display: inline-block;
    background: #fff;
    position: relative;
    border-top: 1px solid #d3d5d6;
    width: 100%;
    height:100vh;
    min-height:650px;
    padding: rem(62) rem(30) 0;
    word-break: keep-all;
  }
  &__img {
    width: 85%;
  }
  &__tit {
    font-size: rem(27);
    font-weight: 400;
    color: #313131;
    margin-top: rem(4);
  }
  &__text {
    font-size: rem(16);
    line-height: rem(24);
    color: #313131;
    font-weight: 300;
    margin-top: rem(19);
    &.-eng {
      font-size: rem(14);
      margin-top: rem(4);
      color: #717171;
      line-height: 1.3;
    }
  }
  &__stext {
    font-size: rem(13);
    line-height: rem(17);
    color: #717171;
    font-weight: 300;
    &:before {
      content: '';
      display: block;
      background: #5673bc;
      width: 50px; height: 2px;
      margin: rem(18) auto rem(15) auto;
    }
  }
  &__btn {
    display: block;
    width: 100%;
    margin-top: rem(15);
  }
  &__link {
    display: block;
    font-size: rem(20);
    color: #fff;
    line-height: rem(24);
    height: rem(78);
    width: 100%;
    padding-top: rem(14);
    border-top-left-radius: 9px;
    border-bottom-right-radius: 9px;
    font-weight: 300;
    &.-link01 {
      background: #367df3 ;
      margin-bottom: 20px;
      .-eng {
        text-transform: uppercase;
        font-size: rem(14);
      }
    }
  }
  &__close {
    float: right;
    right: 30px; top: 30px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
  }
}


html:lang(en) {
  .mainLocation__map {
   background:url("/m/images/main/bg_main_map_eng.jpg") no-repeat center;
    background-size:cover;
  }
  .map__type+.map__type {margin-top:rem(20)}
  .bgVr {bottom:0}
  .mainBanner .bx-pager {bottom:rem(80)}
  .mainBanner__item {height:calc(100vh - 4rem)}
  .mainBanner__inner {margin-top:rem(70)}
  .mainBanner__tit {font-size:rem(15)}
  .mainBanner__sub {font-size:rem(25)}
  .mainBanner__item.item01 {
    .mainBanner__sub {
      padding:0 rem(30);
      line-height:1.4;
    }
  }
  .mainOrganization {
    width:100%;
    height:100%;
    text-align:center;
    &__tit {
      text-align:center;
      display:inline-block;
      padding-bottom:5px;
      font-size:rem(15);
      @include font("bold");
      color:#313131;
      border-bottom:1px solid #313131;
    }
    &__wrap {
      display:block;
      text-align:center;
      vertical-align: middle;
      padding:0 rem(30);
    }
    &__img {
      width:100%;
      height:0;
      padding-top:(551/630*100%);
      background:url("/m/images/main/img_main_organ.jpg") no-repeat 0 0;
      background-size:cover;
    }
    &__type {
      font-size:0;
      margin:rem(20) 0;
      li {
        display:block;
        font-size:rem(19);
        @include font("light");
        color:#313131;
        &:not(:first-child) {
          margin-top:rem(5);
        }
      }
    }
    &__details {
      text-align:left;
      font-size:0;
      h3 {
        display:inline-block;
        font-size:rem(16);
        @include font("light");
        margin:rem(15) 0;
        &:before {
          content:"";
          display:inline-block;
          width:26px;
          height:14px;
          background:url("/m/images/main/iconMain_org.png") no-repeat 0 0;
          margin-right:5px;
          background-size:cover;
        }
      }
    }
    &__btn {
      display:block;
      width:100%;
      border:1px solid #313131;
      color:#313131;
      font-size:rem(16);
      border-radius:15px 0 15px 0;
      text-align:left;
      padding:rem(15) rem(30);
      &:after {
        content:"";
        display:inline-block;
        @include iconNextPage();
        float:right;
        margin-top:5px;
      }
      &:not(:first-of-type) {
        margin-top:10px;
      }
    }
  }

}
