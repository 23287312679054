@charset "UTF-8";
.intro {
  .dotList li:before {
    top:10px;
  }
  &__header {
    width:100%;
    height:0;
    padding-top:(900/750*100%);
    @include before-middle();
    text-align:center;
    background:url("/m/images/info/bg_intro_02.jpg") no-repeat center center;
    background-size:cover;
    position:relative;
    margin-bottom:rem(35);
  }
  &__wrap {
    position:absolute;
    vertical-align: middle;
    color:#fff;
    text-align:left;
    left:0;
    padding:0 rem(30);
    bottom:rem(40);
  }
  &__tit {
    font-size:rem(15);
    height:rem(27);
    @include font("bold");
    border-bottom:2px solid #fff;
    display:inline-block;
  }
  &__commt {
    margin-top:25px;
    font-size:rem(25);
    @include font("light");
  }
  &__orign {
    margin-top:rem(30);
    font-size:rem(12);
    @include font("light");
  }
  &__group {
    width:100%;
    margin-top:rem(20);
    padding:0 rem(30);
    overflow:hidden;
  }
  &__sub {
    font-size:rem(27);
    @include font("dLight");
    color:#3173e6;
    padding-bottom:rem(5);
    display:inline-block;
    border-bottom:2px solid #3173e6;
    margin-bottom:rem(25);
    margin-top:rem(45);
    &:first-of-type {margin-top:rem(25);}
  }
  &__txt {
    font-size:rem(16);
    @include font("dLight");
    line-height:1.6;
    small {font-size:rem(16);display:block;color:#717171;}
  }
  &__img {
    vertical-align: top;
    text-align:center;
    display:inline-block;
    width:100%;
    max-width:630px;
    margin-bottom:rem(45);
  }
  &__prvate {
    &:before {
      content:"";
      display:block;
      width:rem(202);
      height:rem(201);
      background:url("/m/images/info/bg_intro_01.png") no-repeat center center;
      background-size:cover;
      margin:rem(28) auto rem(20);
    }
  }
  &__gvrn {
    &:before {
      content:"";
      display:block;
      width:202px;
      height:201px;
      background:url("/m/images/info/bg_intro_02.png") no-repeat center center;
      background-size:cover;
      margin:rem(40) auto rem(20);
    }
  }
  &__th {
    font-size:rem(20);
    margin-bottom:rem(5);
  }
  .dotList+&__th {
    margin-top:rem(40);
  }
}


.dotList {
  li {
    font-size:rem(16);
    @include font("dLight");
    line-height:1.7;
    position:relative;
    padding-left:14px;
    &:before {
      content:"";
      display:block;
      left:0;
      top:13px;
      width:4px;height:4px;
      vertical-align: middle;
      background:#717171;
      position:absolute;
    }
  }
}


strong {
  @include font("bold");
}

html:lang(en) {
  .intro__img {margin-top:rem(10);display:block;}
}