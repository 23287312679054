    @mixin bg_select {
    width: (24px/2);
    height: (14px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-202px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin btnSearch {
    width: (40px/2);
    height: (40px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-120px/2) (-126px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin btnWhiteClose {
    width: (31px/2);
    height: (31px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-171px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconBannerNext {
    width: (33px/2);
    height: (33px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-44px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconBannerPrev {
    width: (34px/2);
    height: (33px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(0px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconBbsList {
    width: (12px/2);
    height: (20px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-170px/2) (-126px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconBbsListActive {
    width: (12px/2);
    height: (20px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-170px/2) (-80px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconEng {
    width: (34px/2);
    height: (34px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-126px/2) (-80px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconFaceBook {
    width: (36px/2);
    height: (36px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-206px/2) (-64px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconFile {
    width: (32px/2);
    height: (38px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-206px/2) (-110px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconFirstPage {
    width: (20px/2);
    height: (18px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-174px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconLastPage {
    width: (20px/2);
    height: (18px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-146px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconLeftArrow {
    width: (31px/2);
    height: (7px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(0px/2) (-229px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconMainLInk {
    width: (70px/2);
    height: (70px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-126px/2) (0px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconMiner {
    width: (32px/2);
    height: (32px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-129px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconMouse {
    width: (22px/2);
    height: (33px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-40px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconNavNaver {
    width: (38px/2);
    height: (54px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-206px/2) (0px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconNextPage {
    width: (12px/2);
    height: (18px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-22px/2) (-246px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconPlay {
    width: (50px/2);
    height: (50px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-60px/2) (-126px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconPlus {
    width: (32px/2);
    height: (32px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-87px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconPlus_big {
    width: (116px/2);
    height: (116px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(0px/2) (0px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconPrevPage {
    width: (12px/2);
    height: (18px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(0px/2) (-246px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconSearch {
    width: (25px/2);
    height: (25px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-83px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconSelect {
    width: (28px/2);
    height: (18px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (-118px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconSubTab {
    width: (30px/2);
    height: (30px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-212px/2) (-186px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconWorld {
    width: (18px/2);
    height: (16px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-206px/2) (-158px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconX {
    width: (50px/2);
    height: (50px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(0px/2) (-126px/2);
    background-size:(284px/2) (264px/2);
    }
    @mixin iconXblack {
    width: (30px/2);
    height: (30px/2);
    @include txtHidden();
    background-image: url("/m/images/sprite/sprite_m.png");
    background-position:(-254px/2) (0px/2);
    background-size:(284px/2) (264px/2);
    }

    .bg_select {
        @include bg_select;
    }
    .btnSearch {
        @include btnSearch;
    }
    .btnWhiteClose {
        @include btnWhiteClose;
    }
    .iconBannerNext {
        @include iconBannerNext;
    }
    .iconBannerPrev {
        @include iconBannerPrev;
    }
    .iconBbsList {
        @include iconBbsList;
    }
    .iconBbsListActive {
        @include iconBbsListActive;
    }
    .iconEng {
        @include iconEng;
    }
    .iconFaceBook {
        @include iconFaceBook;
    }
    .iconFile {
        @include iconFile;
    }
    .iconFirstPage {
        @include iconFirstPage;
    }
    .iconLastPage {
        @include iconLastPage;
    }
    .iconLeftArrow {
        @include iconLeftArrow;
    }
    .iconMainLInk {
        @include iconMainLInk;
    }
    .iconMiner {
        @include iconMiner;
    }
    .iconMouse {
        @include iconMouse;
    }
    .iconNavNaver {
        @include iconNavNaver;
    }
    .iconNextPage {
        @include iconNextPage;
    }
    .iconPlay {
        @include iconPlay;
    }
    .iconPlus {
        @include iconPlus;
    }
    .iconPlus_big {
        @include iconPlus_big;
    }
    .iconPrevPage {
        @include iconPrevPage;
    }
    .iconSearch {
        @include iconSearch;
    }
    .iconSelect {
        @include iconSelect;
    }
    .iconSubTab {
        @include iconSubTab;
    }
    .iconWorld {
        @include iconWorld;
    }
    .iconX {
        @include iconX;
    }
    .iconXblack {
        @include iconXblack;
    }


